import React from "react"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function NotFound() {
    const pageTitle = "404 Not Found"
    const pageDescription = "Not Found. Independent Forensics is a forensic DNA laboratory that provides DNA testing and conceives, develops, perfects, manufactures, markets and sells new products to forensic laboratories worldwide."

    return (
        <Layout>
            <SEO title={pageTitle} description={pageDescription} />
            <Header headerText="404 Page not found!" />
        </Layout>
    )
}